import './App.css';
import Home from './Pages/Home.jsx';
import Workshops from './Pages/Workshops.jsx';
import About from './Pages/About.jsx';
import Contact from './Pages/Contact.jsx';

import Coaching from './Pages/Coaching.jsx';
import CreativesCoaching from './Pages/CreativesCoaching.jsx';
import EducationConsulting from './Pages/EducationConsulting.jsx';

import PDF_View from './Pages/PDF_View.jsx';
import PDF_Generate from './Pages/PDF_Generate.jsx';
import Login from './Pages/Login.jsx';
import Admin from './Pages/Admin.jsx';
import ProtectedAdminRoute from './Components/ProtectedAdminRoute.jsx'; // Custom route guard
import Payments from './Pages/Payments.jsx';
import PaypalSuccess from './Pages/PaypalRegistrationSuccess.jsx'
import PaypalCancel from './Pages/PaypalRegistrationCancel.jsx'
import PaypalError from './Pages/PaypalRegistrationError.jsx'

import ScrollToTop from './Components/ScrollToTop.jsx'; // on route change, middleware scrolls to top of page


import AuthProvider from './Components/AuthContext.jsx'; // Import the provider

import WorkshopExample from './Pages/Workshops/WorkshopExample.jsx';
import WorkshopSingle from './Pages/WorkshopSingle.jsx';


import Test from './Pages/Test.jsx';




import Header from './Components/Header.jsx';
import Footer from './Components/Footer.jsx';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faBars, faCheckSquare, faEnvelope, faKey, faLock, faUnlock, faLockOpen, faPhone, faMapMarkerAlt, faAnglesLeft, faAngleLeft, faAngleRight, faAnglesRight } from '@fortawesome/free-solid-svg-icons'

import React from 'react';

import { BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';




library.add(fab, faCheckSquare, faBars, faEnvelope, faKey, faLock, faUnlock, faLockOpen, faPhone, faMapMarkerAlt, faAnglesLeft, faAngleLeft, faAngleRight, faAnglesRight)

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <ScrollToTop />
        <MainLayout />
      </Router>
    </AuthProvider>
    );
};

const MainLayout = () => {

  return (
      <div className="MainLayoutWrap">
        <Header />

        <div className="ContentWrap">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/workshops" element={<Workshops />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/coaching" element={<Coaching />} />
            <Route path="/creatives-coaching" element={<CreativesCoaching />} />
            <Route path="/education-consulting" element={<EducationConsulting />} />

            <Route path="/pdf-view" element={<PDF_View />} />
            <Route path="/pdf-generate" element={<PDF_Generate />} />
            <Route path="/login" element={<Login />} />
            <Route path="/payments" element={<Payments />} />
            <Route path="/register-workshop-paypal-success" element={<PaypalSuccess />} />
            <Route path="/register-workshop-paypal-cancel" element={<PaypalCancel />} />
            <Route path="/register-workshop-paypal-error" element={<PaypalError />} />

            <Route path="/workshop-example" element={<WorkshopExample />} />
            <Route path="/workshop/:id" element={<WorkshopSingle />} />

            <Route path="/test" element={<Test />} />


            <Route element={<ProtectedAdminRoute />} >
              {/* Protetcted Admin Routes Here! */}
              <Route path="/admin" element={<Admin />} />
            </Route>

            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </div>
        <Footer />
      </div /*End PageWrap*/> 
  );
};

export default App;
