import React from 'react';
import { useLocation } from 'react-router-dom';

const PaypalSuccess = () => {
  // Get query params from the URL (e.g., orderId)
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const orderId = queryParams.get('orderId');

  return (
    <div>
      <h1>Payment Successful!</h1>
      <p>Your order ID is: {orderId}</p>
      <p>Thank you for your purchase.</p>
    </div>
  );
};

export default PaypalSuccess;
