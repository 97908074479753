import ImageUploader from "../Components/ImageUploader.jsx"
import ImageGallery from "../Components/ImageGallery.jsx"
import "../Components/MediaManager.css"
import React, { useState, useEffect }  from 'react';

const MediaManager = ({ selectedImages, selectImages }) => {
  const [view, setView] = useState('upload'); // switch between 'upload' and 'gallery'

    // Function to move an item from one index to another
  const moveItem = (array, fromIndex, toIndex) => {
    const newArray = [...array]; // Create a new copy of the array
    const item = newArray.splice(fromIndex, 1)[0]; // Remove the item from the original position
    newArray.splice(toIndex, 0, item); // Insert it at the new position
    return newArray; // Return the new array
  };

  const [inputsOrder, setInputsOrder] = useState(
    selectedImages.map((_, index) => index) // Track order input values
  );

  useEffect(() => {
  	console.log("SELCTED IMAGES:", selectedImages);
    setInputsOrder(selectedImages.map((_, index) => index)); // Reset inputsOrder to match indices
  }, [selectedImages]);



  const handleInputsReorder = (index, newValue) => {
  	if (newValue >= 0 && newValue < selectedImages.length) {
	    const updatedValues = [...inputsOrder];
	    updatedValues[index] = newValue; // Temporarily store the new input value
	    setInputsOrder(updatedValues);
	}
  };

  // Function to handle reordering on blur (after leaving the input field)
  const performReorder = (index) => {
    const newIndex = parseInt(inputsOrder[index], 10); // Convert to zero-based index

    if (!isNaN(newIndex) && newIndex >= 0 && newIndex < selectedImages.length && newIndex !== index) {
      const reorderedImages = moveItem(selectedImages, index, newIndex);
      selectImages(reorderedImages); // Update the images state
      setInputsOrder(reorderedImages.map((_, idx) => idx)); // Update the input values to reflect the new order
    }
  };

  const removeImage = (index) => {
  	const updatedImages = selectedImages.filter((_, i) => i !== index); // Filter out the image at the specified index
    selectImages(updatedImages);
  }

  return (
    <div className="media-manager-wrap">
      <div className="media-manager-menu">
      	<button 
      		onClick={() => setView('upload') }
      		style={{
      			backgroundColor: (view === 'upload') ? '#723AA8' : '#DDCAEF', 
      			fontWeight: (view === 'upload') ? 'bold' : 'normal',
      			color: (view === 'upload') ? 'white' : '#723AA8'

      		}}
      	>
      		Upload Images
      	</button>
      	<button 
      		onClick={() => setView('gallery')}
      		style={{
      			backgroundColor: (view === 'gallery') ? '#723AA8' : '#DDCAEF', 
      			fontWeight: (view === 'gallery') ? 'bold' : 'normal',
      			color: (view === 'gallery') ? 'white' : '#723AA8'
      		}}
      	>
      		Select Images
      	</button>
      </div>
      <div className="media-manager-page">
	      {view === 'upload' && <ImageUploader selectedImages={selectedImages} selectImages={selectImages} />}
	      {view === 'gallery' && <ImageGallery selectedImages={selectedImages} selectImages={selectImages} />}
      </div>
  	  <div className="selected-images-wrap">
      	<h3>Selected Images</h3>

			{selectedImages.length > 0 ? (
			  <div className="selected-images-grid">
			    {selectedImages.map((curImg, index) => (
			      <div className="selected-image-card" key={curImg.uuid}> {/* Added key prop for list rendering */}
		        	<div className="img-wrap">
				        <img 
				          src={`/client/uploads/${curImg.uuid}.${curImg.ext}`}
				          alt={curImg.name} // Always add alt text for accessibility
				        />
				    </div>
			        {/*<p 
			          id={`${curImg.uuid}.${curImg.ext}`}
			        > 
			          {curImg.name} 
			        </p> */}
			        <div className="selected-btns-wrap">
				        <input 
				          type="numeric" 
				          name="img-order"
				          value={inputsOrder[index]}
				          onChange={(e) => handleInputsReorder(index, e.target.value)} // Update the input value on change
				          onBlur={() => performReorder(index)}
				          min="0"
				          max={selectedImages.length - 1}
				        />
				        <input
				          type="button"
				          value="x"
				          onClick={() => removeImage(index)} // Pass index directly
				        />
				    </div>
			      </div>
			    ))}
			  </div>
			) : (
			  <p>No images selected</p>
			)}


      </div> {/*End Selected Images Wrap*/}

    </div>
  );
};

export default MediaManager;
