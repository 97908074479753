import './Header.css'; 
import {Menu, BurgerLinks} from './Menu.jsx';
import { useLocation, useNavigate} from 'react-router-dom';
import React, { useState, useContext } from 'react';

import { AuthContext } from './AuthContext.jsx';


const Header = () => {
	const { isAuthenticated, logout } = useContext(AuthContext);
	const navigate = useNavigate();

	const handleLogout = () => {
		logout(); // Call logout from context
		//navigate('/login'); // Navigate after logout
	};


	const location = useLocation();
	{/* const shouldShowHeader = [ '/', '/contact', '/about', '/coaching', '/creatives-coaching',
		'/pdf-view', '/pdf-generate', '/login', '/payments', '/workshops', '/workshop-example'].includes(location.pathname);
	*/}

	const menuHiddenPages = ['/pdf-view'].includes(location.pathname);

	// shared state for menu components
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const toggleMenu = () => {
	    setIsMenuOpen(!isMenuOpen);
	  };

  return ( 
  	<header>
  		{isAuthenticated && (<div className="logout-button-wrap">
          <button onClick={handleLogout} className="logout-button">Logout</button>
          </div>
        )}

  		{/*<img src="/abstract_wave.jpg" alt="Logo" className="header-image" />*/}
  		<div className="header-content">
  			<div className="branding-wrap">
  				<h1 className="site-title">Lace Campbell</h1>
  				<h4 className="tagline"><i>Client Centered Consultaion</i></h4>
  			</div>
  			{!menuHiddenPages && <Menu isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />}
  		</div>
  		<div>
  			{!menuHiddenPages && <BurgerLinks isMenuOpen={isMenuOpen} />}
  		</div>
  	</header>
  );
 };

export default Header;



