import React from 'react';
import FillablePdf from './../Components/PDF_Create.jsx';

const PDF_Generate = () => {
  return (
    <div >
      <h2>Create Fillable PDF Form</h2>
      <FillablePdf />
      
    </div>
  );
};

export default PDF_Generate;