import React from 'react';
import PdfViewer from './../Components/PDF_Viewer.jsx';

const PDF_View = () => {
  return (
    <div>
      <h2>View/Edit PDF </h2>
      <PdfViewer />
    </div>
  );
};

export default PDF_View;
