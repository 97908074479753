// Menu.js

import { Link, NavLink } from 'react-router-dom';
import './Menu.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react';




export const Menu = ( {isMenuOpen, toggleMenu} ) => {
  return (
    <div className="MenuWrap">
      {/* Full menu for larger screens */}
      <div className="full-menu">
        <nav className="menu">
          <ul>
            <li><NavLink to="/">Home</NavLink></li>
            <li><NavLink to="/workshops">Workshops</NavLink></li>
            <li className="drop-parent"><a>Coaching</a>
              <ul className="drop-list origin-right">
                <li><NavLink to="/coaching">Individual, Group & Couples Coaching</NavLink></li>
                <li><NavLink to="/creatives-coaching">Coaching for Creatives</NavLink></li>
                <li><NavLink to="/education-consulting">Education Consulting</NavLink></li>
              </ul> 
            </li>
            <li className="hdr-contact-btn"><NavLink to="/contact">Contact Me</NavLink></li>

            {/*
              <li><NavLink to="/about">About</NavLink></li>
              <li className="drop-parent"><a>Coaching</a>
                <ul className="drop-list origin-right">
                  <li><a>Individual, Group & Couples Coaching</a></li>
                  <li><a>Coaching for Creatives</a></li>
                </ul> 
              </li>
              <li><NavLink to="/pdf-view">PDF View</NavLink></li>
              <li><NavLink to="/pdf-generate">PDF Generate</NavLink></li>
              <li><NavLink to="/about">About 2</NavLink></li>
              <li><NavLink to="/about">About 3</NavLink></li>
            */}
          </ul>
        </nav>
      </div>

      {/* Burger menu for smaller screens */}
      <div className={"burger-icon-wrap " + (isMenuOpen ? "burger-open" : "burger-closed")} >
        <div className="burger-icon" >
          <FontAwesomeIcon icon="bars" size="lg" onClick={toggleMenu} />
        </div>
      </div>
    </div>
  );

};


export const BurgerLinks = ( {isMenuOpen} ) => {
  /*const [isMenuOpen, setIsMenuOpen] = useState(true);*/
  return (
    <div className="burger-links">
      { 
      isMenuOpen && (
          <nav className="menu">
            <ul>
              <li><NavLink to="/">Home</NavLink></li>
              <li><NavLink to="/workshops">Workshops</NavLink></li>
              <li><NavLink to="/coaching">Individual, Group & Couples Coaching</NavLink></li>
              <li><NavLink to="/creatives-coaching">Coaching for Creatives</NavLink></li>
              <li><NavLink to="/education-consulting">Education Consulting</NavLink></li>

              <li><NavLink to="/contact">Contact Me</NavLink></li>
              {/*
              <li><NavLink to="/about">About</NavLink></li>
              <li><NavLink to="/pdf-view">PDF View</NavLink></li>
              <li><NavLink to="/pdf-generate">PDF Generate</NavLink></li>
              <li><NavLink to="/about">About 2</NavLink></li>
              <li><NavLink to="/about">About 33</NavLink></li>
              */}

            </ul>
          </nav>
        )
        
  }
    </div>
  );
};



