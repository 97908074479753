import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom';



const Footer = () => {
  const year = new Date().getFullYear(); // Get the current year

  return ( 
    <footer>
      <div className="footer-container"> 

        <div className="footer-top">

          <div className="footer-column">
            <h2 className="site-title" >Lace Campbell</h2>
            <Link to="/contact" className="contact-link">Contact Me</Link>
          </div>

          <div className="footer-column">
            <div className="contact-info">
              <p><FontAwesomeIcon icon="phone" className="form-label-fa-symbol" />333-333-3333</p>
              <p><FontAwesomeIcon icon="envelope" className="form-label-fa-symbol" />lacecampbell@lacecampbell.com</p>
              <p><FontAwesomeIcon icon="map-marker-alt" className="form-label-fa-symbol" />123 Main Street, Buffalo, NY 14201</p>

            </div>
            <div className="social-media-links">
              <a href="https://facebook.com/myapp" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'facebook']} /></a>
              <a href="https://twitter.com/myapp" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'twitter']} /></a>
              <a href="https://instagram.com/myapp" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'instagram']} /></a>
              <a href="https://linkedin.com/company/myapp" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'linkedin']} /></a>
            </div>
          </div>

      </div> {/* End footer-top */}

      <div className="footer-middle">

        <li><Link to="/">Home</Link></li>
        <li><Link to="/workshops">Workshops</Link></li>
        <li><Link to="/coaching">Coaching</Link></li>
        <li><Link to="/creatives-coaching">Coaching for Creatives</Link></li>
        <li><Link to="/education-consulting">Education Consulting</Link></li>

        <li><Link to="/contact">Contact Me</Link></li>
        <li><Link to="/payments">Payments</Link></li>

      </div> {/* End footer-middle */}

      <div className="footer-bottom">
        <p>© {year}</p>
      </div>

    </div> {/* End footer-container */}


    </footer>
  );
 };

export default Footer;