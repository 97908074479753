import React, { useState } from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import Modal from './Modal.jsx'; 
import './PaypalButtons.css';

const PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID;

const PaypalButtons = () => {
  const [cost, setCost] = useState('');
  const [isFormVisible, setIsFormVisible] = useState(true);

  const [modalMessage, setModalMessage] = useState('');
  const [modalTitle, setModalTitle] = useState('');

  const closeModal = () => {
    setModalMessage('');
    setModalTitle('');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Validate the cost
    if (parseFloat(cost) > 0) {
      setIsFormVisible(false); // Hide the form and show PayPal buttons
    } else {
      alert('Please enter a valid amount.');
    }
  };

  const handlePaymentSuccess = () => {
    setModalMessage('Thank you for your payment!');
    setModalTitle('Payment Received');
    setIsFormVisible(true); // Show the form again
    setCost(''); // Reset the cost

  };

  const handlePaymentCancel = () => {
    setIsFormVisible(true); // Show the form again
    setModalMessage('Your payment was canceled');
    setModalTitle('Payment Canceled');
  };

  const handlePaymentError = () => {
    setIsFormVisible(true); // Show the form again
    setModalMessage('There was an error making your payment');
    setModalTitle('Payment Error');
  }; 


  return (
    <div className="center-wrap">
      <PayPalScriptProvider options={{ "client-id": PAYPAL_CLIENT_ID, components: "buttons", "enable-funding": "venmo" }}>
        {isFormVisible ? (
          <form onSubmit={handleSubmit}>
            <h2>Enter Amount</h2>
            <div>
              <label htmlFor="cost">Amount (USD): </label>
              <input
                type="number"
                id="cost"
                name="cost"
                value={cost}
                onChange={(e) => setCost(e.target.value)}
                step="0.01"
                min="0.01"
                required
              />
            </div>
            <button type="submit">Submit</button>
          </form>
        ) : (
          <div className="form-style">
            <h2>Pay with PayPal</h2>
            <PayPalButtons
              createOrder={(data, actions) => {
                return actions.order.create({
                  purchase_units: [{
                    amount: {
                      currency_code: 'USD',
                      value: cost // Use the user-entered amount
                    }
                  }]
                });
              }}
              onApprove={async (data, actions) => {
                return actions.order.capture().then(details => {
                  console.log('Transaction completed by ' + details.payer.name.given_name);
                  handlePaymentSuccess(); 
                });
              }}
              onCancel={async () => {
                console.log('Payment was canceled.');
                handlePaymentCancel(); 
              }}
              onError={(err) => {
                console.error('PayPal Button Error:', err);
                handlePaymentError(); 

              }}
            />
          </div>
        )}
      </PayPalScriptProvider>
      <Modal message={modalMessage} title={modalTitle} onClose={closeModal} />

    </div>
  );
};

export default PaypalButtons;





/*
import React, { useState } from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import './PaypalButtons.css'; 


const PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID


const PaypalButtons = () => {
  // PayPal client ID
  //const clientId = 'YOUR_CLIENT_ID'; // Replace with your PayPal client ID

  // State to store user-entered amount
  const [cost, setCost] = useState('0.01'); // Default amount

  const handleChange = (e) => {
    const value = parseFloat(e.target.value).toFixed(2); // Ensure 2 decimal places
    setCost(value);
    console.log('Amount updated to:', value); // Debugging

  };

  return (
    <div className="center-wrap">
    <PayPalScriptProvider options={{ "client-id": PAYPAL_CLIENT_ID }}>
      <div>
        <h2>Pay with PayPal</h2>
        {}
        <div>
          <label htmlFor="cost">Amount (USD): </label>
          <input
            type="number"
            id="cost"
            name="cost"
            value={cost}
            onChange={handleChange}
            step="0.01"
            min="0.01"
          />
        </div>
        
        <PayPalButtons
          createOrder={(data, actions) => {
            return actions.order.create({
              purchase_units: [{
                amount: {
                  currency_code: 'USD',
                  value: cost // Use the user-entered amount
                }
              }]
            });
          }}
          onApprove={async (data, actions) => {
            return actions.order.capture().then(details => {
              console.log('Transaction completed by ' + details.payer.name.given_name);
              // Handle the successful payment
            });
          }}
          onError={(err) => {
            console.error('PayPal Button Error:', err);
          }}
        />
      </div>
    </PayPalScriptProvider>
    </div>
  );
};

export default PaypalButtons;
*/

/*
import React, { useEffect } from "react";

const PaypalButtons = () => {
  useEffect(() => {
    // Dynamically load the PayPal SDK script
    const script = document.createElement("script");
    script.src = "https://www.paypal.com/sdk/js?client-id=BAADTL9O9PQUlINY1WoyGlTz4CEDvmaB5rrI5VvT2C1w4yDrf0ESl3GHbyM6_ja69oI21_UmR0GG1bvNPU&components=hosted-buttons&enable-funding=venmo&currency=USD";
    script.async = true;
    script.crossOrigin = "anonymous";
    document.body.appendChild(script);

    script.onload = () => {
      if (window.paypal) {
        // Ensure PayPal SDK is available and then render the hosted button
        window.paypal.HostedButtons({
          hostedButtonId: "5SUCRG384XHV8" // Predefined hosted button ID from PayPal
        }).render("#paypal-container-5SUCRG384XHV8");
      }
    };

    // Cleanup the script on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <h2>Pay with PayPal</h2>
      {}
      <div id="paypal-container-5SUCRG384XHV8"></div>
    </div>
  );
};

export default PaypalButtons;
*/
