import React from 'react';
import { PDFDocument, rgb } from 'pdf-lib';
import { saveAs } from 'file-saver';

const FillablePdf = () => {
  const createPdf = async () => {
    // Create a new PDF document
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage([600, 400]);
    const { width, height } = page.getSize();

    // Define offsets
    const headerOffset = 80;
    const formFieldOffset = 150;

    // Add header text
    const headerTitle = 'Informed Consent for Individual Therapy';
    const headerBody = `
      Welcome: Before starting your therapy, it is important to know what to expect, and to 
      understand your rights as well as commitments. This consent form is an attempt to be 
      as transparent with you as I can about the therapy process, so you are fully informed 
      prior to starting your journey.
    `;

    // Add the larger header title text
    const titleTextWidth = headerTitle.length * 7; // Rough estimation of text width
    page.drawText(headerTitle, {
      x: (width - titleTextWidth) / 2 - 25, // Center the title
      y: height - headerOffset,
      size: 18,
      color: rgb(0, 0, 0),
      maxWidth: width - 100,
    });

    // Add the rest of the header text
    page.drawText(headerBody, {
      x: 25, // Left justify the body text
      y: height - headerOffset - 40, // Adjusted to move the text down from the title
      size: 12,
      color: rgb(0, 0, 0),
      lineHeight: 18,
      maxWidth: width - 50,
    });

    // Add text fields labels with separate offset
    page.drawText('Name:', { x: 50, y: height - headerOffset - formFieldOffset, size: 12 });
    page.drawText('Date of Birth:', { x: 50, y: height - headerOffset - formFieldOffset - 30, size: 12 });
    page.drawText('Signature:', { x: 50, y: height - headerOffset - formFieldOffset - 60, size: 12 });
    page.drawText('Verify Signature:', { x: 50, y: height - headerOffset - formFieldOffset - 90, size: 12 });

    // Define text field positions and sizes
    const fields = [
      { name: 'name', x: 150, y: height - headerOffset - formFieldOffset, width: 300, height: 20 },
      { name: 'dob', x: 150, y: height - headerOffset - formFieldOffset - 30, width: 200, height: 20 },
      { name: 'signature', x: 150, y: height - headerOffset - formFieldOffset - 60, width: 300, height: 20 }
    ];

    // Add text fields with invisible borders
    for (const { name, x, y, width, height } of fields) {
      const textField = pdfDoc.getForm().createTextField(name);
      textField.setText('');
      textField.addToPage(page, { x, y, width, height });
      
      // Set the appearance to have no visible border
     // textField.setBorderWidth(0); // Set border width to 0
      //textField.setBorderStyle('solid'); // Set border style to solid (even though it's invisible)
    }

    // Add checkbox field
    const checkbox = pdfDoc.getForm().createCheckBox('verify_signature');
    checkbox.addToPage(page, { x: 150, y: height - headerOffset - formFieldOffset - 90, width: 20, height: 20 });

    // Serialize the PDF to bytes
    const pdfBytes = await pdfDoc.save();

    // Save the PDF
    saveAs(new Blob([pdfBytes], { type: 'application/pdf' }), 'fillable-form.pdf');
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <button onClick={createPdf}>Generate PDF</button>
    </div>
  );
};

export default FillablePdf;





/*
import React from 'react';
import { PDFDocument, rgb} from 'pdf-lib';
import { saveAs } from 'file-saver';

const FillablePdf = () => {
  const createPdf = async () => {
    // Create a new PDF document
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage([600, 400]);
    const { width, height } = page.getSize();

    // Add text fields
    page.drawText('Name:', { x: 50, y: height - 50, size: 12 });
    page.drawText('Date of Birth:', { x: 50, y: height - 80, size: 12 });
    page.drawText('Signature:', { x: 50, y: height - 110, size: 12 });
    page.drawText('Verify Signature:', { x: 50, y: height - 140, size: 12 });

    // Define text field positions and sizes
    const fields = [
      { name: 'name', x: 150, y: height - 50, width: 300, height: 20 },
      { name: 'dob', x: 150, y: height - 80, width: 200, height: 20 },
      { name: 'signature', x: 150, y: height - 110, width: 300, height: 20 }
    ];

    // Add text fields
    for (const { name, x, y, width, height } of fields) {
      const textField = pdfDoc.getForm().createTextField(name);
      textField.setText('');
      textField.addToPage(page, { x, y, width, height });
    }

    // Add checkbox field
    const checkbox = pdfDoc.getForm().createCheckBox('verify_signature');
    checkbox.addToPage(page, { x: 150, y: height - 140, width: 20, height: 20 });

    // Serialize the PDF to bytes
    const pdfBytes = await pdfDoc.save();

    // Save the PDF
    saveAs(new Blob([pdfBytes], { type: 'application/pdf' }), 'fillable-form.pdf');
  };

  return (
    <div>
      <button onClick={createPdf}>Generate PDF</button>
    </div>
  );
};

export default FillablePdf;
*/
