import React, { createContext, useState, useEffect } from 'react';

// Create the AuthContext
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [loading, setLoading] = useState(true);

  // Function to check if the user is authenticated based on the token
  useEffect(() => {
    const checkToken = async () => {
      const token = localStorage.getItem('jwtToken');
      if (!token) {
        setIsAuthenticated(false);
        setLoading(false);
        return;
      }

      try {
        const response = await fetch('/api/verify-token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        const data = await response.json();
        if (response.ok && data.success) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error('Token validation error:', error);
        setIsAuthenticated(false);
      } finally {
        setLoading(false); // Token validation is complete
      }
    };

    checkToken();
  }, []);

  // Function to handle login
  const login = async (email, password, recaptchaValue) => {
    try {
      const response = await fetch('/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password, recaptchaValue }),
      });

      const result = await response.json();

      if (result.success) {
        localStorage.setItem('jwtToken', result.token);
        setIsAuthenticated(true); // Set auth state on successful login
        return { success: true };
      } else {
        return { success: false, message: result.message };
      }
    } catch (error) {
      console.error('Login error:', error);
      return { success: false, message: 'Failed to log in.' };
    }
  };

  // Function to handle logout
  const logout = () => {
    // Remove the JWT token from localStorage
    localStorage.removeItem('jwtToken');
    
    // Set isAuthenticated to false
    setIsAuthenticated(false);
  };


  // Context provider value
  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, loading, login, logout }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

