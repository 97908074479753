import React from 'react';
import './Lightbox.css'; // Add styles in this CSS file

const Lightbox = ({ onClose, children, buttonText = 'Save' }) => {
  return (
    <div className="lightbox-overlay">

      <div className="lightbox-content">
        <div className="lightbox-static-header">
          <span className="close" onClick={onClose}>&times;</span>
        </div>
        <div className="lightbox-scrollable">
          {children}
        </div>
        <div className="lightbox-static-footer">
          <button className="done-button" onClick={onClose}>{buttonText}</button>
        </div>
      </div>

    </div>
  );
};

export default Lightbox;
