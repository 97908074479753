import React from 'react';

const About = () => {

  const repeats = 100; // Number of <h1>.</h1> elements you want to create
  const repeatsArr = [];

  // Loop to create the <h1>.</h1> elements
  for (let i = 0; i < repeats; i++) {
    repeatsArr.push(<h1 key={i}>.</h1>);
  }


  return ( 
    <div>
      <h2>About Page</h2>
      <img src="/abstract_wave.jpg" alt="Logo" className="header-image" />

      {repeatsArr}

    </div>
  );
 };

export default About;
