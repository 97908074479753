import React from 'react';
import { useLocation } from 'react-router-dom';

const PaypalCancel = () => {
  // Get query params from the URL (e.g., orderId)
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const orderId = queryParams.get('orderId');

  return (
    <div>
      <h1>Payment Canceled!</h1>
      <p>Your canceled order ID is: {orderId}</p>
      <p>Thank you.</p>
    </div>
  );
};

export default PaypalCancel;
