import React, { useEffect, useState }  from 'react';
import SearchAndPage from './SearchAndPage.jsx'

const ImageGallery = ({ selectedImages, selectImages}) => {

	const [images, setImages] = useState([]);
	const objectNameStr = {single: "image", multi: "images", one_or_more: "image(s)"};


	// Toggle image selection
	const handleImageClick = (image) => {
	  selectImages(prevSelected => {
	    // Check if the image is already selected based on uuid
	    const isSelected = prevSelected.some(selected => selected.uuid === image.uuid);

	    if (isSelected) {
	      // Remove from selection if already selected
	      return prevSelected.filter(selected => selected.uuid !== image.uuid);
	    } else {
	      // Add to selection
	      return [...prevSelected, image];
	    }
	  });
	};

	return (
	    <div>
	    	<h2>Image Gallery NEW</h2>

	    	<SearchAndPage 
	    		fetchUrl="/api/image-gallery"
	    		setObjects={setImages}
	    		objectNameStr={objectNameStr}
	    	/>

		    <div className="gallery-images" >
		        {images.length > 0 ? (
		          images.map((image, index) => (
				<div 
				  className="img-wrap"
	              key={index}
	              name={image.name} 
	              style={{ 
	                //border: selectedImages.includes(image) ? '3px solid blue' : '1px solid gray',
	                  border: selectedImages.some(selected => selected.uuid === image.uuid) ? '3px solid blue' : '3px solid gray', 
	              }}
	              onClick={() => handleImageClick(image)} // Toggle selection on click
	            >
	              <img
	                src={`/client/uploads/${image.uuid}.${image.ext}`}
	                alt={`${image.uuid}.${image.ext}`}
	              />
	            </div>
		          ))
		        ) : (
		          <p>No images found</p>
		        )}
		    </div>

	    </div>
	);

};

export default ImageGallery;