import React, { useState, useRef, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom';
import '../Components/Form.css';
import ReCAPTCHA from 'react-google-recaptcha'
import { AuthContext } from '../Components/AuthContext.jsx';  // Import the useAuth hook from your context

const RECAPTCHA_V2_PUBLIC = process.env.REACT_APP_RECAPTCHA_V2_PUBLIC;


const Login = () => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [recaptchaError, setRecaptchaError] = useState(false);

  const recaptchaRef = useRef(null); // Reference to the reCAPTCHA component

  const navigate = useNavigate();

  // check if logged in already
  const { login, isAuthenticated, loading } = useContext(AuthContext);  // Access login and isAuthenticated from context
  // If already authenticated, redirect to admin
  useEffect(() => {
    if (isAuthenticated) {
      navigate('/admin');
    }
  }, [isAuthenticated, navigate]);

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
    setRecaptchaError(false); 
  };

  const handleSubmit = async (event) => {
      event.preventDefault();

      // Basic client-side validation
      if (email === '' || password === '') {
          setError('Please fill in both fields.');
          return;
      }

      setError('');

      // check reCaptcha
      if (!recaptchaValue) {
        setRecaptchaError(true);
        //setModalMessage('Please complete the reCaptcha.');
        alert("Please complete the reCaptcha");
        return;
      }

      //alert(`Form submitted: ${email} - ${password} - ${recaptchaValue}`);

      try {
        const result = await login(email, password, recaptchaValue);  // Use login from context
        if (result.success) {
          // Redirect to admin after successful login
          navigate('/admin');
        } else {
          // Handle error (if any returned)
          setError(result.message || 'Login failed');
        }
      } catch (error) {
        // Handle any errors that occurred during the fetch
        console.error('Error during login:', error);
        alert('Failed to log in. Please try again later.');
      }

      /*
      try {
        const response = await fetch('/api/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, password, recaptchaValue}),
        });

        const result = await response.json();
        console.log("Server Response:" + result.message);
        alert(result.message);

        if (result.success) {
          localStorage.setItem('jwtToken', result.token); // Or Cookie???
          navigate('/admin');
          //alert('Logged In!');
        }
      }
      catch (error) {
          // Handle any errors that occurred during the fetch
          console.error('Error during login:', error);

          // You could display an error message to the user
          alert('Failed to log in. Please try again later.');
      }
      */

  }; // End of handleSubmit()

  if (loading) {
    return <p>Loading...</p>; // or a loading spinner
  }

  return ( 
    <div>
      <h2>Login Page</h2>
        <form onSubmit={handleSubmit}>
          <label htmlFor="email"><FontAwesomeIcon icon="envelope" className="form-label-fa-symbol" />Email
            <input
                type="text"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
            />
          </label>
          
          <label htmlFor="password"><FontAwesomeIcon icon="lock-open" className="form-label-fa-symbol" />Password 
            <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
            />
          </label>

          <div className="recaptcha-wrap" >
            {recaptchaError && <p className="form-error-text">reCAPTCHA is required</p>}

            <ReCAPTCHA
              sitekey={RECAPTCHA_V2_PUBLIC}
              onChange={handleRecaptchaChange}
              ref={recaptchaRef} 
            />
          </div>
          
          <input type="submit" value="Login" />

          {error && <p className="error">{error}</p>}
      </form>
    </div>
  );
 };

export default Login;