import React from 'react';
import WorkshopForm from '../../Components/WorkshopForm.jsx';


const WorkshopExample = () => {

  return ( 
    <div>

      {/*<div className="pic-text-2">
        <img src="/figma-imgs/dancers.png" className="pic-text-2-pic" />
        <div className="pic-text-2-text">
          <h2>Workshop Example</h2>
          <p>As a creative, you know the power of imagination and innovation—but balancing inspiration with structure can be challenging. Our coaching for creatives is designed to help you break through creative blocks, refine your artistic vision, and turn your passions into a sustainable career.</p>         
          <p>Let us help you nurture your creativity, refine your process, and reach your fullest potential as an artist. Your creative breakthrough starts here!</p>
        </div>
      </div>
      */}

    <div className="workshop-header-wrap">
      <img src="/figma-imgs/rocks.png" alt="Description" className="workshop-header-img" />
      <div className="workshop-header-text">
        <h2 className="workshop-header-title">Example Workshop</h2>
        <p className="workshop-date">Jan 1 2024</p>
        <p className="workshop-location">123 Main St., Cambridge, MA</p>
        <p className="workshop-cost">$60</p>
      </div>
    </div>

    <p>This workshop focuses on enhancing communication skills within relationships. Participants will learn practical techniques to express their thoughts and emotions more effectively, understand their partner's perspective, and navigate conflicts with empathy and clarity. </p>
    <p>Through interactive exercises and guided discussions, attendees will gain tools to foster deeper connections and create a more harmonious and supportive relationship environment. Ideal for couples, families, or individuals seeking to improve their relational dynamics.</p>

      <WorkshopForm workshopID="1" submitBtnText="Register" />

    </div>
  );
 };

export default WorkshopExample;