import React, { useState }  from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Paginate = ({ 
	pageNum, 
	setPageNum, 
	pageNumInput, 
	setPageNumInput, 
	totalPages, 
	setTotalPages, 
	totalObjects, 
	setTotalObjects, 
	objectNameStr 
}) => {


	//const windowSize = 5;

	//const [pageNum, setPageNum] = useState(0);
	//const [pageNumInput, setPageNumInput] = useState(1); // seperated for debouncing

	//const [totalPages, setTotalPages] = useState(0);
	//const [totalObjects, setTotalObjects] = useState(0);

	const handleFirstPage = () => {
	    setPageNum(0);
	    setPageNumInput(1);
	  }

	  const handlePrevPage = () => {
	    const newPageNum = Math.max(pageNum - 1, 0);
	    setPageNum(newPageNum);
	    setPageNumInput(newPageNum + 1);
	  }

	  const handleNextPage = () => {
	    const newPageNum = Math.min(totalPages - 1, pageNum + 1);
	    setPageNum(newPageNum);
	    setPageNumInput(newPageNum+1);
	  }

	  const handleLastPage = () => {
	    setPageNum(totalPages - 1);
	    setPageNumInput(totalPages);
	  }

	  const handlePageInputChange = (e) => {
	    setPageNumInput(e.target.value);
	  };

		const handlePageChange = () => {
		  let newPage = parseInt(pageNumInput) - 1; // Convert input to zero-indexed page
		  if (!isNaN(newPage)) {
		    newPage = Math.max(0, Math.min(newPage, totalPages - 1)); // Clamp page between 0 and totalPages - 1
		    setPageNum(newPage);
		    setPageNumInput(newPage + 1); // Reflect the valid page in the input field
		  } else {
		    // If invalid input, reset the input to the current page
		    setPageNumInput(pageNum + 1);
		  }
		};

	return (
	    <div className="pagination-bar"> 
        <div className="">{totalObjects} {objectNameStr.one_or_more}</div>
        <button onClick={handleFirstPage} disabled={pageNum === 0}><FontAwesomeIcon icon="angles-left" className="pagination-chevrons" /></button>
        <button onClick={handlePrevPage} disabled={pageNum === 0}><FontAwesomeIcon icon="angle-left" className="pagination-chevrons" /></button>
        <span className="">
          <input
            type="numeric"
            value={pageNumInput}
            onChange={handlePageInputChange}
            onBlur={handlePageChange}
          />
        </span>
        <div>of {totalPages}</div>
        <button onClick={handleNextPage} disabled={pageNum >= totalPages - 1}><FontAwesomeIcon icon="angle-right" className="pagination-chevrons" /></button>
        <button onClick={handleLastPage} disabled={pageNum >= totalPages - 1}><FontAwesomeIcon icon="angles-right" className="pagination-chevrons" /></button>
      </div>
	);

};

export default Paginate;