// src/Components/PdfConsentForm.jsx
// src/Components/PdfFormFiller.jsx
/*
import React, { useState } from 'react';
import { PDFDocument } from 'pdf-lib';
import { saveAs } from 'file-saver';

const PdfViewer = () => {
  const [formData, setFormData] = useState({
    name: '',
    dob: '',
    signature: '',
    verify_signature: false,
  });

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async () => {
    const pdfUrl = '/fillable-form.pdf'; // Path to your predefined PDF

    // Fetch the PDF document
    const pdfBytes = await fetch(pdfUrl).then(res => res.arrayBuffer());

    // Load the PDF document
    const pdfDoc = await PDFDocument.load(pdfBytes);

    // Get the form from the PDF
    const form = pdfDoc.getForm();

    // Fill out the form fields
    form.getTextField('name').setText(formData.name);
    form.getTextField('dob').setText(formData.dob);
    form.getTextField('signature').setText(formData.signature);

    formData.verify_signature && form.getCheckBox('verify_signature').check(true);

    // Save the filled PDF
    const filledPdfBytes = await pdfDoc.save();

    saveAs(new Blob([filledPdfBytes], { type: 'application/pdf' }), 'filled-form.pdf'); // save to google drive instead 
  };

  return (
    <div>
      <h1>Fill PDF Form</h1>
      <div>
        <label>
          Name:
          <input type="text" name="name" value={formData.name} onChange={handleInputChange} />
        </label>
        <label>
          Date of Birth:
          <input type="text" name="dob" value={formData.dob} onChange={handleInputChange} />
        </label>
        <label>
          Signature:
          <input type="text" name="signature" value={formData.signature} onChange={handleInputChange} />
        </label>
        <label>
          Verify Signature:
          <input type="checkbox" name="verify_signature" checked={formData.verify_signature} onChange={handleInputChange} />
        </label>
        <button onClick={handleSubmit}>Submit</button>
      </div>
    </div>
  );
};

export default PdfViewer;
*/




import React, { useState } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import { PDFDocument } from 'pdf-lib';
import { saveAs } from 'file-saver';
import './PdfViewer.css'; // Add your custom styles here

const PdfViewer = () => {
  const [pdfUrl, setPdfUrl] = useState('/docfly.pdf'); // Path to your consent form PDF
  const [file, setFile] = useState(null);
  const [isFileSelected, setIsFileSelected] = useState(false);


  // Handle file input change
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setIsFileSelected(true);

  };

  // Handle form submission
  const handleSubmit = async () => {
    if (!file) return;

    // Load existing PDF document
    const pdfDoc = await PDFDocument.load(await file.arrayBuffer());

    // Access form fields and set values
    const form = pdfDoc.getForm();
    form.getTextField('name').setText('John Doe'); // Example field
    form.getTextField('dateOfBirth').setText('01/01/2000'); // Example field


    // Fill out the PDF fields here (e.g., using pdf-lib)
    // Example: const form = pdfDoc.getForm();
    // form.getTextField('name').setText('John Doe');

    // Save the filled PDF
    const pdfBytes = await pdfDoc.save();
    saveAs(new Blob([pdfBytes], { type: 'application/pdf' }), 'filled-consent-form.pdf');
  };

  return (
    <div className="pdf-consent-form">
      <h1>View and Fill Consent Form</h1>
      <input
        type="file"
        accept="application/pdf"
        onChange={handleFileChange}
      />
      
        <button onClick={handleSubmit}>Submit Filled Consent Form</button>


      <div className="pdf-container">
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
          <Viewer fileUrl={pdfUrl} plugins={[defaultLayoutPlugin()]} />
        </Worker>
      </div>
    </div>
  );
};

export default PdfViewer; 

