import React, { useState, useEffect } from 'react';

import MediaManager from "../Components/MediaManager.jsx"
import Lightbox from "../Components/Lightbox.jsx"
import Modal from "../Components/Modal.jsx"

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const WorkshopEditor = ({selectedWorkshop, refreshWorkshops, resetSelection}) => {

	const [selectedImages, selectImages] = useState([]); // for Image Gallery Component

	const [isLightboxOpen, setIsLightboxOpen] = useState(false);

	const [workshopData, setWorkshopData] = useState({
		workshopTitle: '',
	    workshopCost: '0.00',
	    workshopDate: '',
	    workshopStartTime: '',
	    workshopEndTime: '',
	    workshopLocation: '',
	    workshopDescription: '',
	    workshopExcerpt: '',
	    workshopStatus: 'draft',
	    workshopAutoGen: 1,
	});

	const [tempCost, setTempCost] = useState('0.00');

	const openLightbox = () => {
		setIsLightboxOpen(true);
	};

	const closeLightbox = () => {
	    setIsLightboxOpen(false);
	};

	const isEditing = !!selectedWorkshop; // Check if editing mode


	const [modalMessage, setModalMessage] = useState('');
	const [modalTitle, setModalTitle] = useState('');

	const closeModal = () => {
		setModalTitle("");
		setModalMessage("");
	}

	// Clear the form if creating a new workshop
	const resetForm = () => {
      setWorkshopData({
        workshopTitle: '',
        workshopCost: '0.00',
        workshopDate: '',
        workshopStartTime: '',
        workshopEndTime: '',
        workshopLocation: '',
        workshopDescription: '',
        workshopExcerpt: '',
        workshopStatus: 'draft',
        workshopAutoGen: 1,
      });
     selectImages([]);
     setTempCost('0.00');
	 }


	// Populate form with selected workshop data if editing
	const [loading, setLoading] = useState(false); // Add loading state

  // Fetch the workshop data based on selectedWorkshop ID if editing
  useEffect(() => {
    const fetchWorkshopData = async (id) => {
      setLoading(true);  // Set loading state before fetching
      try {
        const response = await fetch(`/api/get-workshop-by-id/${id}`, {
          method: 'POST',
          headers: { 
          	'Content-Type': 'application/json',
          },
          body: JSON.stringify({
	          fields: ['id', 
	          	'title', 
	          	'cost', 
	          	'date',
	          	'start_time', 
	          	'end_time',
	          	'location',
	          	'description',
	          	'excerpt',
	          	'status',
	          	'auto_gen',
	          	'images'
	          	]
	        }),

        });

        const result = await response.json();
        if (result.success) {
          // Populate form with fetched data
          setWorkshopData({
            workshopTitle: result.workshop.title,
            workshopCost: result.workshop.cost,
            workshopDate: new Date(result.workshop.date).toLocaleDateString('en-CA', {year: 'numeric', month: '2-digit', day: '2-digit'}),
            workshopStartTime: result.workshop.start_time,
            workshopEndTime: result.workshop.end_time,
            workshopLocation: result.workshop.location,
            workshopDescription: result.workshop.description,
            workshopExcerpt: result.workshop.excerpt,
            workshopStatus: result.workshop.status,
            workshopAutoGen: result.workshop.auto_gen,
          });

          console.log("result:", result);
          selectImages(result.workshop.images || []);
          setTempCost(result.workshop.cost.toString());

        } else {
          console.error('Error fetching workshop:', result.message);
        }
      } catch (error) {
        console.error('Error fetching workshop:', error);
      } finally {
        setLoading(false);  // Reset loading state after fetching
      }
    };

    if (selectedWorkshop) {
      // If there is a selected workshop, fetch the details
      fetchWorkshopData(selectedWorkshop.id);
    } else {
      resetForm();	
    }
  }, [selectedWorkshop]);

	// Handle form input changes
	const handleInputChange = (e) => {
		const { name, value } = e.target;

		if (name === 'workshopCost') {
			let parsedCost = parseFloat(tempCost);

		    // Validate and format the cost
		    if (!isNaN(parsedCost)) {
		        parsedCost = parsedCost.toFixed(2); // Fix to two decimal places
		        setWorkshopData((prevData) => ({
		            ...prevData,
		            workshopCost: parsedCost, // Update workshopCost with validated cost
		        }));
		        setTempCost(parsedCost); // Also update tempCost for consistency
		    } else {
		        // If invalid, reset tempCost to previous workshopCost
		        setTempCost(workshopData.workshopCost);
		    }

		}
		else {
	      setWorkshopData({ ...workshopData, [name]: value }); 
		}
	};

	const handleQuillChange = (value) => {
	  setWorkshopData((prevData) => ({
	    ...prevData,
	    workshopDescription: value, // Set the value from ReactQuill
	  }));
	};



	const handleTempCost = (e) => {
		setTempCost(e.target.value)
	};

	const handleCostBlur = () => {
	    let parsedCost = parseFloat(tempCost);

	    // Validate and format the cost
	    if (!isNaN(parsedCost)) {
	        parsedCost = parsedCost.toFixed(2); // Fix to two decimal places
	        setWorkshopData((prevData) => ({
	            ...prevData,
	            workshopCost: parsedCost, // Update workshopCost with validated cost
	        }));
	        setTempCost(parsedCost); // Also update tempCost for consistency
	    } else {
	        // If invalid, reset tempCost to previous workshopCost
	        setTempCost(workshopData.workshopCost);
	    }
	};

	// Submit form - handles both create and edit
	const submitWorkshop = async (e) => {
	    e.preventDefault();
	    const token = localStorage.getItem('jwtToken'); 

	    const url = isEditing
	      ? `/api/edit-workshop/${selectedWorkshop.id}`
	      : '/api/create-workshop';

	    try {
	      const response = await fetch(url, {
	        method: 'POST',
	        headers: { 
	        	'Content-Type': 'application/json',
	            'Authorization': `Bearer ${token}`,
 			},
	        body: JSON.stringify({...workshopData, selectedImages}),
	      });
	      const result = await response.json();
	      if (result.success) {
	        setModalTitle("Workshop saved!");
	        setModalMessage("");
	        //refreshWorkshops();  // Refresh workshop list in parent
	      } else {
	      	setModalTitle("Error");
	        setModalMessage(result.message);
	      }
	    } catch (error) {
	      console.error('Error saving workshop:', error);
	    }
	};

	const deleteWorkshop = async (id) => {
		const token = localStorage.getItem('jwtToken'); // Replace with your actual key

		try {
	        const response = await fetch(`/api/delete-workshop/${id}`, {
	          method: 'POST',
	          headers: { 
	          	'Content-Type': 'application/json',
	            'Authorization': `Bearer ${token}`,
	      	  },
	        });

	        const result = await response.json();
	        if (result.success) {
	        	setModalTitle(`Workshop ${id} Deleted`);
	        	setModalMessage("");
	        	refreshWorkshops();
	        }
	        else {
	        	setModalTitle("Oops...");
	        	setModalMessage(`Couldn't delete workshop ${id}`);
	        }
	    } catch (error) {
	    	setModalTitle("Error");
	        setModalMessage(`Issue deleting workshop ${id}`);
	    }
	};


	const handleDeleteWorkshop = () => {
		setModalTitle("Delete Workshop?");
		setModalMessage(
			<div>
				<button
					name="cancel-delete"
					value="cancel-delete"
					onClick={ closeModal }
				>Cancel
				</button>
				<button
					name="choose-delete"
					value="choose-delete"
					onClick={ () => deleteWorkshop(selectedWorkshop.id) }
				>Delete
				</button>
			</div>
		);
	};

	return (
	    <div className="edit-workshop">
	      <h2>{isEditing ? `Edit Workshop: ${selectedWorkshop.id}` : 'Create New Workshop'}</h2>

	      	<div>

      		<input 
	          	type="submit"
	          	name="Select Image(s)"
	          	value="Select Image(s)"
	          	onClick={openLightbox}
	          />
	        
	          {isLightboxOpen && (
	            <Lightbox
	              onClose={closeLightbox}
	              children={
	                <MediaManager selectedImages={selectedImages} selectImages={selectImages} />
	              }
	            />
	          )}
	        </div>

	        <input
	          type="text"
	          name="workshopTitle"
	          placeholder="Workshop Title"
	          value={workshopData.workshopTitle}
	          onChange={handleInputChange}
	        />

	        <div className="short-ans-wrap">

	          <label htmlFor="workshop-status">Cost:</label>
	          <input
	            type="numeric"//"number"//"numeric"
	            /*step=".01"
	            min="0"*/
	            name="workshopCost"
	            value={tempCost}//{workshopData.workshopCost}
	            onChange={handleTempCost}
	            onBlur={handleInputChange}
	          />

	          <input
	            type="date"
	            name="workshopDate"
	            value={workshopData.workshopDate}
	            onChange={handleInputChange}
	          />

	          <input
	            type="time"
	            name="workshopStartTime"
	            value={workshopData.workshopStartTime}
	            onChange={handleInputChange}
	          />

	          <input
	            type="time"
	            name="workshopEndTime"
	            value={workshopData.workshopEndTime}
	            onChange={handleInputChange}
	          />
	        </div>

	        <input 
	          type="text"
	          name="workshopLocation" 
	          placeholder="Workshop Location"
	          value={workshopData.workshopLocation}
	          onChange={handleInputChange}
	        />

	        {/*<textarea
	          name="workshopDescription"
	          placeholder="Workshop Description"
	          value={workshopData.workshopDescription}
	          onChange={handleInputChange}
	          rows="4" // Sets the number of visiblsle rows
	          cols="50" // Sets the number of visible columns
	        />*/}

	        <ReactQuill
	        	//name="workshopDescription"
	        	placeholder="Workshop Description"
	        	value={workshopData.workshopDescription}
	        	onChange={handleQuillChange}
	        />

	        <textarea
	          name="workshopExcerpt"
	          placeholder="Workshop Excerpt"
	          value={workshopData.workshopExcerpt}
	          onChange={handleInputChange}
	          rows="4" // Sets the number of visiblsle rows
	          cols="50" // Sets the number of visible columns
	        />
	        <div className="selects-wrap">

	          <label htmlFor="workshopStatus">Status:</label>
	          <select 
	            name="workshopStatus"
	            value={workshopData.workshopStatus}
	            onChange={handleInputChange}
	          >
	            <option value="draft">Draft</option>
	            <option value="published">Published</option>
	          </select>

	          <label htmlFor="workshopAutoGen">Auto Generate:</label>
	          <select 
	            name="workshopAutoGen"
	           	value={workshopData.workshopAutoGen}
	            onChange={handleInputChange}
	          >
	            <option value={1}>Version #1</option>
	            <option value={0}>No</option>
	          </select>
	        </div>

	        <div className="submit-wrap">
	          <input 
	            type="submit"
	            name={isEditing ? 'Update Workshop' : 'Create Workshop'}
	            value={isEditing ? 'Update Workshop' : 'Create Workshop'}
	            onClick={submitWorkshop}
	          />
	          {isEditing && (
		          <input 
		          	type="submit"
		          	name="Delete Workshop"
		          	value="Delete Workshop"
		          	className="delete-workshop-btn"
		          	onClick={handleDeleteWorkshop}
		          />
	          )}

	        </div>

	        <Modal message={modalMessage} title={modalTitle} onClose={closeModal} />

	    </div>
	);



}

export default WorkshopEditor;