import React, {useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import WorkshopForm from '../Components/WorkshopForm.jsx';

const WorkshopSingle = () => {
	const { id } = useParams();

	const [workshop, setWorkshop] = useState(null); // State to store workshop details
	const [loading, setLoading] = useState(true); // State to handle loading
	const [error, setError] = useState(null); // State to handle errors

	// Fetch workshop details when component mounts or when the `id` changes
	useEffect(() => {
		const fetchWorkshop = async () => {
		  	try {

		    	const response = await fetch(`/api/get-workshop-by-id/${id}`, {
		          method: 'POST',
		          headers: { 'Content-Type': 'application/json' },
		          body: JSON.stringify({
			        fields: [
			          	'id', 
			          	'title', 
			          	'cost', 
			          	'date', 
			          	'start_time', 
			          	'end_time', 
			          	'location', 
			          	'description', 
			          	'auto_gen', 
			          	'images'
		          	]
			      }),

		        });


		    	// Adjust endpoint to match your backend
		    	if (!response.ok) {
		      	throw new Error('Failed to fetch workshop details');
		    	}

		    	const data = await response.json();
		    	setWorkshop(data.workshop); // Set the workshop data
		  	} catch (err) {
		    	setError(err.message); // Set the error message
		  	} finally {
		    	setLoading(false); // Set loading to false once the request is done
		  	}
		};
		fetchWorkshop();
	}, []); // Re-run useEffect if `id` changes


	const costToStr = (cost) => {
	  const parsedCost = cost.endsWith('.00') ? parseInt(cost) : cost; //remove trailing .00
	  return parsedCost === 0 ? 'FREE' : `$${parsedCost}`;
	};

	// Handle loading state
	if (loading) {
		return <p>Loading workshop details...</p>;
	}

	// Handle error state
	if (error) {
		return <p>Error: {error}</p>;
	}


	return (

		<div>
			<div className="workshop-header-wrap">
		      <img src={`/client/uploads/${workshop.images[0].uuid}.${workshop.images[0].ext}`} alt="Workshop Image" className="workshop-header-img" />
		      <div className="workshop-header-text">
		        <h2 className="workshop-header-title">{workshop.title}</h2>
		        <p className="workshop-date">{				        		
		        	new Date(workshop.date).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
				}</p>

		        <p className="workshop-location">{workshop.location}</p>
		        <p className="workshop-cost">{costToStr(workshop.cost)}</p>
		      </div>
		    </div>

		    <div
		    	dangerouslySetInnerHTML={{ __html: workshop.description }}
		    />
		    <WorkshopForm workshopID={workshop.id} submitBtnText={workshop.cost > 0 ? "Register & Pay" : "Register"}/>

		</div>
	);

};

export default WorkshopSingle;