import React, { useState }  from 'react';

const SearchBar = ({ 
	searchString,
	setSearchString,
	startDate,
	setStartDate,
	endDate,
	setEndDate,
	handleQuery }) => {


	const handleSearchString = (e) => {
		setSearchString(e.target.value);
	}

	const handleStartDate = (e) => {
		setStartDate(e.target.value);
	}

	const handleEndDate = (e) => {
		setEndDate(e.target.value);
	}

	return (
    	<div className="search-bar">
	        <input 
	          type="text"
	          value={searchString}
	          onChange={handleSearchString}
	          placeholder="Search..."
	        />
	        <span>
	          StartDate:&nbsp;
	          <input 
	            type="date"
	            value={startDate}
	            onChange={handleStartDate}
	          />
	        </span>
	        <span>
	          EndDate:&nbsp;
	          <input 
	            type="date"
	            value={endDate}
	            onChange={handleEndDate}
	          />
	        </span>
	        <input
	          type="submit"
	          value="Search"
	          onClick={handleQuery}
	        />
	    </div>

	);

};

export default SearchBar;