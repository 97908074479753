import React from 'react';
//import { useLocation } from 'react-router-dom';

const PaypalError = () => {
  // Get query params from the URL (e.g., orderId)
  //const location = useLocation();
  //const queryParams = new URLSearchParams(location.search);
  //const orderId = queryParams.get('orderId');

  return (
    <div>
      <h1>Payment Error!</h1>
      <p>Oops Something went wrong...</p>
      <p>Please try again.</p>
    </div>
  );
};

export default PaypalError;
