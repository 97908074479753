import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

const Workshops = () => {

	const [autoGenWorkshops, setAutoGenWorkshops] = useState([]);
	const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

	useEffect(() => {
		const fetchWorkshops = async () => {
      try {
        const response = await fetch('/api/get-workshops-filtered', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            searchString: '',
            startDate: '',
            endDate: '',
            status: 'published',
            autoGen: -1,
            pageNum: 0,
            windowSize: -1,
            fields: ['id', 'title', 'cost', 'date', 'start_time', 'end_time', 'location', 'excerpt', 'images']
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to fetch workshops');
        }

        const data = await response.json();

        if (data.success) {
          setAutoGenWorkshops(data.objects); 
        } else {
          setError('No workshops found.');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchWorkshops();
  }, []);


  const staticWorkshopsRef = useRef(null);
  const [hasStaticWorkshops, setHasStaticWorkshops] = useState(false);

  useEffect(() => {
    if (staticWorkshopsRef.current) {
      // Check if there are any children with the 'workshop-row' class
      const workshopRows = staticWorkshopsRef.current.getElementsByClassName('workshops-row');
      if (workshopRows.length > 0) {
        setHasStaticWorkshops(true);
      } else {
        setHasStaticWorkshops(false);
      }
    }
  }, []);


	const costToStr = (cost) => {
	  const parsedCost = cost.endsWith('.00') ? parseInt(cost) : cost; //remove trailing .00
	  return parsedCost === 0 ? 'FREE' : `$${parsedCost}`;
	};



return (
    <div>


      <div className="header-img-wrap">
        <img src="/figma-imgs/purple-mountains.png" alt="Logo" className="header-image" />
        <h3 className="header-text">Workshops</h3>
      </div>

      <div className="workshops-list">

      	<div className="static-workshops" ref={staticWorkshopsRef}>

      	
	      	<div className="workshops-row">
		        <img src="/figma-imgs/rocks.png" alt="" className="workshops-img" />
		        <Link to="/workshop-example" className="workshops-card">
		        	<h3 className="workshops-title">Workshop Heading</h3>
		        	<p className ="workshops-date">Jan 1, 2017</p>
		        	<p className ="workshops-location">12 Main St Buffalo NY</p>
	        		<p className="workshops-desc">This is a description of the workshop that describes what participants can expect and what topics will be covered. </p>
		        	<p className="workshops-cost" >$60</p>

		        </Link>
	        </div>

	        <div className="workshops-row">
	        	<img src="/figma-imgs/path.png" alt="" className="workshops-img" />
	        	<Link to="/workshop-example" className="workshops-card">
		        	<h3 className="workshops-title">Workshop Heading</h3>
		        	<p className ="workshops-date">Jan 1, 2017</p>
		        	<p className ="workshops-location">12 Main St Buffalo NY</p>
	        		<p className="workshops-desc">This is a description of the workshop that describes what participants can expect and what topics will be covered. </p>
			   		<p className="workshops-cost" >$60</p>
		        </Link>
	        </div>
	        

	      </div> {/* End Static Workshops */}

       <div className="auto-gen-workshops">

       	{
	       	autoGenWorkshops.length > 0 ? (
					  autoGenWorkshops.map((workshop, index) => (

					  	<div 
							  className="workshops-row"
				        key={index}
				        name={workshop.title} 
				      >
				      	<img src={`/client/uploads/${workshop.images[0].uuid}.${workshop.images[0].ext}`} alt="" className="workshops-img" />
				      	<Link to={`/workshop/${workshop.id}`} className="workshops-card">
				      		<h3 className="workshops-title">{workshop.title}</h3>
				        	<p className ="workshops-date">{
				        		new Date(workshop.date).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
				        	}</p>
				        	<p className ="workshops-location">{workshop.location}</p>
			        		<p className="workshops-desc"> {workshop.excerpt} </p>
					   			<p className="workshops-cost" >{costToStr(workshop.cost)}</p>
				       	</Link>
				      </div>
					          	
					  ))
					) : ( !hasStaticWorkshops && <div>No Workshop At the Moment</div>

					)

       	} 

       </div> {/* End Auto Gen Workshops */}


        
{/*

				<div className="workshops-row">
        	<img src="/figma-imgs/path.png" alt="" className="workshops-img" />
        	<Link to="/workshop-example" className="workshops-card">
	        	<h3 className="workshops-title">Workshop Heading</h3>
	        	<p className ="workshops-date">Jan 1 2017</p>
	        	<p className ="workshops-location">12 Main St Buffalo NY</p>
        		<p className="workshops-desc">This is a description of the workshop that describes what participants can expect and what topics will be covered. </p>
		   		<p className="workshops-cost" >$60</p>
	        </Link>
        </div>

				<div className="workshops-row">
	        <img src="/figma-imgs/sunset.png" alt="" className="workshops-img" />
	        <Link to="/workshop-example" className="workshops-card">
	        	<h3 className="workshops-title">Workshop Heading</h3>
	        	<p className ="workshops-date">Jan 1 2017</p>
	        	<p className ="workshops-location">12 Main St Buffalo NY</p>
        		<p className="workshops-desc">This is a description of the workshop that describes what participants can expect and what topics will be covered. </p>
	    			<p className="workshops-cost" >$60</p>
	    		</Link>
				</div>

      	<div className="workshops-row">
	        <img src="/figma-imgs/hands.png" alt="" className="workshops-img" />
	        <Link to="/workshop-example" className="workshops-card">
	        	<h3 className="workshops-title">Workshop Heading</h3>
	        	<p className ="workshops-date">Jan 1 2017</p>
	        	<p className ="workshops-location">12 Main St Buffalo NY</p>
        		<p className="workshops-desc">This is a description of the workshop that describes what participants can expect and what topics will be covered. </p>
		   			<p className="workshops-cost" >$60</p>
		    	</Link>
				</div>
			*/}

	  	</div> {/* End Workshops List*/}

  	</div>
  );
};

export default Workshops;
