import React, { useState } from 'react';


const ImageUploader = ({ selectedImages, selectImages}) => {

  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('');
  const [keywords, setKeywords] = useState('');

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleKeywordsChange = (e) => {
    setKeywords(e.target.value);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedFile) {
      alert('Please select an image first!');
      return;
    }

    const token = localStorage.getItem('jwtToken'); // Replace with your actual key

    const formData = new FormData();
    formData.append('image', selectedFile);
    formData.append('keywords', keywords);

    try {
      const response = await fetch('/api/upload-img', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        setUploadStatus(`Upload successful! File path: /client/uploads/${data.file.filename}`);
        selectImages((prev) => [...prev, ...data.images]);
        console.log("IMAGE:", data.images);
        console.log("SELECTED:", selectedImages);
      } else {
        setUploadStatus('Upload failed.');
      }
    } catch (error) {
      console.error('Error uploading the image:', error);
      setUploadStatus('Upload failed.');
    }
  };

  return (
    <div>
      <h2>Upload an Image</h2>
      <form onSubmit={handleSubmit}>
        <input 
          type="file" 
          accept="image/*" 
          onChange={handleFileChange} 
        />
        <input 
          type="text" 
          onChange={handleKeywordsChange}
          placeholder="Keywords"
          name="keywords"
        />

        <button type="submit">Upload</button>
      </form>
      {uploadStatus && <p>{uploadStatus}</p>}
    </div>
  );
}

export default ImageUploader;


