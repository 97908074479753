import React, { useContext, useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import {AuthContext} from './AuthContext.jsx'; // Import the AuthContext

const ProtectedAdminRoutes = () => {
  const { isAuthenticated, loading } = useContext(AuthContext); // Use AuthContext
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading) {
      // If user is not authenticated and loading is complete, redirect to login
      if (!isAuthenticated) {
        navigate('/login');
      }
    }
  }, [isAuthenticated, loading, navigate]);

  // If still loading, display loading message
  if (loading) {
    return <div>Loading...</div>;
  }

  // If authenticated, render the protected content
  if (isAuthenticated) {
    return <Outlet />;
  }

  // If not authenticated, we redirect using the effect, so nothing is rendered here
  return null;
};

export default ProtectedAdminRoutes;

/*
import React, { useEffect, useState } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';

const ProtectedAdminRoutes = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null); // Track authentication state
  const navigate = useNavigate();

  useEffect(() => {
    const checkToken = async () => {
      const token = localStorage.getItem('jwtToken');

      if (!token) {
        // No token, redirect to login
        setIsAuthenticated(false);
        console.log("No JWT found in local storage");
        return;
      }

      try {
        // Send token to backend for validation using fetch
        const response = await fetch('/api/verify-token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Include token in Authorization header
          }
        });

        const data = await response.json();

        if (response.ok && data.success) {
          // Token is valid
          setIsAuthenticated(true);
          console.log("Verified!")
        } else {
          // Token is invalid, redirect to login
          setIsAuthenticated(false);
          console.log("Verification failed!");
        }
      } catch (error) {
        // In case of error (e.g., token expired), redirect to login
        setIsAuthenticated(false);
        console.log("Verification Error!");
      }
    };

    checkToken();
  }, [navigate]);

  // If authentication status is not determined, show a loading state
  if (isAuthenticated === null) {
    return <div>Loading...</div>;
  }

  // If authenticated, render the Outlet (protected content)
  if (isAuthenticated) {
    return <Outlet />;
  }

  // If not authenticated, redirect to login
  if (!isAuthenticated) {
    navigate('/login');
    return null;
  }
};

export default ProtectedAdminRoutes;
*/


/*
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

// Custom ProtectedRoute component
const ProtectedAdminRoute = () => {
  const token = localStorage.getItem('jwt');

  // Check if the token exists
  if (!token) {
    return <Navigate to="/login" />;
  }

  // Optionally decode the token to check for expiration
  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Current time in seconds

    // If the token is expired, redirect to login
    if (decodedToken.exp < currentTime) {
      return <Navigate to="/login" />;
    }
  } catch (error) {
    // If there's an error decoding the token, treat it as invalid
    return <Navigate to="/login" />;
  }

  // If the token is valid, allow access to the protected route
  return <Outlet />;
};
*/