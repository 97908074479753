import React, { useState } from 'react';
import WorkshopEditor from './WorkshopEditor.jsx';
import SearchAndPage from './SearchAndPage.jsx'
import './WorkshopSelector.css'


const WorkshopSelector = () => {

	const [editorOpen, setEditorOpen] = useState(false);
	const [selectedWorkshop, setSelectedWorkshop] = useState(null);
	const [workshopList, setWorkshopList] = useState([]);
	const objectNameStr = {single: "workshop", multi: "workshops", one_or_more: "workshop(s)"};


	const refreshWorkshops = () => {
		setSelectedWorkshop(null);
		setEditorOpen(false);
	};

	const resetSelection = () => {
	}; 

	const handleWorkshopSelect = (workshop) => {
		setSelectedWorkshop(workshop);
		setEditorOpen(true);
	};

	const newWorkshopMode = () => {
		setEditorOpen(true);
		setSelectedWorkshop(null);
	};

	const selectWorkshopMode = () => {
		setEditorOpen(false);
	};

	return (
		<div>

			<div className="workshop-editor-mode-bar">
				<input 
					type="submit"
					value="+"
					onClick={newWorkshopMode}
				/>
				<input 
					type="submit"
					value="Select Workshop"

					onClick={selectWorkshopMode}
				/>
			</div>

			{!editorOpen &&
				<div>
					<SearchAndPage
						fetchUrl="/api/get-workshops-filtered"
			    		setObjects={setWorkshopList}
			    		objectNameStr={objectNameStr}

					/>

					<div className="workshop-container">
						{workshopList.length > 0 ? (
				          workshopList.map((workshop, index) => (
						<div 
						  className="workshop-item"
			              key={index}
			              name={workshop.title} 
			              style={{ 
			                  //border: selectedImages.some(selected => selected.uuid === image.uuid) ? '3px solid blue' : '3px solid gray', 
			              }}
			              onClick={() => handleWorkshopSelect(workshop)} // Toggle selection on click
			            >
			              <p>{workshop.title}</p>
			            </div>
				          ))
				        ) : (
				          <p>No workshops found</p>
				        )}
					</div>	
				</div>
			}

			{editorOpen &&
				<WorkshopEditor
					selectedWorkshop={selectedWorkshop} 
					refreshWorkshops={refreshWorkshops} 
					resetSelection={resetSelection} 
				/>
			}

		</div>
	);

};

export default WorkshopSelector;