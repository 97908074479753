import React from 'react';
import ContactForm from './../Components/ContactForm.jsx';

const RECAPTCHA_V2_PUBLIC = process.env.REACT_APP_RECAPTCHA_V2_PUBLIC;

const Coaching = () => {
  return ( 
    <div>
      <div className="pic-text-2">
        <img src="/figma-imgs/group-coaching.png" className="pic-text-2-pic" />
        <div className="pic-text-2-text">
          <h2>Individual, Group & Couples Coaching</h2>
          <p>Unlock your creative potential with personalized coaching designed to meet your unique needs and goals. Our sessions provide tailored support to help you overcome challenges, develop your skills, and achieve your aspirations. </p>
          <p>Whether you're seeking personal growth, stronger relationships, or professional development, our coaching services are designed to meet your unique needs. We offer a range of coaching options to suit different goals and challenges</p>
        </div>
      </div>

      <h3 className="centered">Please reach out and I'll contact you for an initial conversation!</h3>

      <ContactForm RECAPTCHA_V2_PUBLIC={RECAPTCHA_V2_PUBLIC} />

    </div>
  );
 };

export default Coaching;
