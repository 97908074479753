import React, { useState, useEffect }  from 'react';
import SearchBar from './SearchBar.jsx';
import Paginate from './Paginate';

const SearchAndPage = ({ fetchUrl, setObjects, objectNameStr }) => {

	const [searchString, setSearchString] = useState("");
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');
	const [submitSearchTrigger, triggerFetch] = useState(0);

	const windowSize = 5;
	const [pageNum, setPageNum] = useState(0);
	const [pageNumInput, setPageNumInput] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [totalObjects, setTotalObjects] = useState(0);

	  const fetchObjects = async () => {
	    try {
	      const response = await fetch(fetchUrl, {
	        method: 'POST',
	        headers: {
	          'Content-Type': 'application/json',
	        },
	        body: JSON.stringify({
	          searchString,
	          startDate,
	          endDate,
	          pageNum,
	          windowSize,
	          fields: ['id', 'title']
	        }),
	      });
	      const result = await response.json();

	      if (response.ok && result.success) {
	        setObjects(result.objects);
	        setTotalPages(Math.ceil(result.totalObjects / windowSize)); // Set total pages dynamically based on the result
	        setTotalObjects(result.totalObjects);
	        //setErrorMessage(''); // reset errorMessage
	        //console.log("Num Images:", result.totalImages);
	      } else {
	        //setErrorMessage(result.message || 'Error fetching images');
	      }
	    } catch (error) {
	      //setErrorMessage('Server error: ' + error.message);
	    }
	  };

	useEffect(() => {
	    fetchObjects(); // Fetch images on component load or whenever dependencies change
	}, [pageNum, submitSearchTrigger]);

	const handleQuery = async () => {
		//fetchImages();
		setPageNum(0); // reset pagination
		setPageNumInput(1);
		const triggerVal = (submitSearchTrigger === 0 ? 1 : 0);
		triggerFetch(triggerVal); //ensure new 
	}

	return (
		<div>
			<SearchBar 
				searchString={searchString}
				setSearchString={setSearchString} 
				startDate={startDate} 
				setStartDate={setStartDate} 
				endDate={endDate}
				setEndDate={setEndDate}
				handleQuery={handleQuery}
			/>
			<Paginate 
				pageNum={pageNum}
				setPageNum={setPageNum} 
				pageNumInput={pageNumInput}
				setPageNumInput={setPageNumInput}
				totalPages={totalPages} 
				setTotalPages={setTotalPages} 
				totalObjects={totalObjects}
				setTotalObjects={setTotalObjects}
				objectNameStr={objectNameStr}
			/>
		</div>
	);
};

export default SearchAndPage;
