import { useState, useRef } from 'react';
import Modal from '../Components/Modal.jsx'; // Import the Modal component
import ReCAPTCHA from 'react-google-recaptcha'

const RECAPTCHA_V2_PUBLIC = process.env.REACT_APP_RECAPTCHA_V2_PUBLIC;


const WorkshopForm = ( {workshopID, submitBtnText = "Submit"} ) => {



  const [inputs, setInputs] = useState("");
  const [errors, setErrors] = useState({});

  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [recaptchaError, setRecaptchaError] = useState(false);

  const [modalMessage, setModalMessage] = useState('');
  const [modalTitle, setModalTitle] = useState('');

  const recaptchaRef = useRef(null); // Reference to the reCAPTCHA component

  const validateForm = () => {
    const newErrors = {};
    const phoneRegex = /^\d{10}$/; // 10 digits
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!inputs.firstname) newErrors.firstname = '* First Name is required';
    if (!inputs.lastname) newErrors.lastname = '* Last Name is required';
    if (!inputs.email) { newErrors.email = '* Email is required'; }
    else if (inputs.email && !emailRegex.test(inputs.email)) { newErrors.email = '* Please use a valid email address'; }
    if (!inputs.phone) newErrors.phone = '* Phone Number is required';
    else if (inputs.phone && !phoneRegex.test(inputs.phone)) { newErrors.phone = '* Phone should be 10 digits, no other characters'; }

 	// add phone pattern validation
    {/*if (!inputs.dob) newErrors.dob = 'Date of Birth is required'; */}

   
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}));
    setErrors((errors) => ({ ...errors, [name]: '' })); 
  }

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
    setRecaptchaError(false); 

  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      setModalTitle("Form Error");
      setModalMessage('Please fill out all required fields.');
      
      return; 
    }

    if (!recaptchaValue) {
      setRecaptchaError(true);
      setModalMessage('Please complete the reCaptcha.');
      return;
    }

    setModalTitle("Registering...");
    setModalMessage("Please wait momentarily");

    let result;
    try {
      const response = await fetch('/api/workshop-register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...inputs, recaptchaValue, workshopID }),
      });

      result = await response.json();
    } catch (error) {
      setModalTitle("Server Error");
      setModalMessage(error.message);
      return;
    }

    if (result.success) {
    	setModalTitle(result.title);
    	setModalMessage("");

    	//handle paypal redirect
      if (result.approvalUrl) { 
			  const windowPos=(window.screen.width / 2) - (600 / 2);
		      //redirect to paypal
		      //window.location.href = result.approvalUrl;
		      //window.open(result.approvalUrl, '_blank');
		      window.open( result.approvalUrl, 'PayPal Checkout', `width=600,height=600,left=${windowPos}`);
		      closeModal();

		      // reset form
		      setInputs({
			    firstname: '',
			    lastname: '',
			    email: '',
			    phone: '',
			  });

		      //reset var that tracks recaptcha state
		      setRecaptchaValue(null);
		      //reset recaptcha 
		      if (recaptchaRef.current) {
		        recaptchaRef.current.reset();
		      }
		    return;
	  	}
		}
		else {
			setModalTitle("Registration Error");
    	setModalMessage(result.message);
		}    
  }

  const closeModal = () => {
    setModalMessage('');
    setModalTitle('');
  };

  return (
  	<div>

      	<form onSubmit={handleSubmit}>

      	<h3 className="">Register</h3>

      	 <label>
	        {errors.firstname && <p className="form-error-text">{errors.firstname}</p>}
	        <input 
	          type="text" 
	          name="firstname" 
	          placeholder="First Name"
	          value={inputs.firstname || ""} 
	          onChange={handleChange}
	        />
	      </label>

      	  <label>
	        {errors.lastname && <p className="form-error-text">{errors.lastname}</p>}
	        <input 
	          type="text" 
	          name="lastname" 
	          placeholder="Last Name"
	          value={inputs.lastname || ""} 
	          onChange={handleChange}
	        />
	      </label>

	      <label>
	        {errors.email && <p className="form-error-text">{errors.email}</p>}
	        <input 
	          type="text" 
	          name="email" 
	          placeholder="Email"
	          value={inputs.email || ""} 
	          onChange={handleChange}
	        />
	      </label>

	      <label>
	        {errors.phone && <p className="form-error-text">{errors.phone}</p>}
	        <input 
	          type="tel" 
	          name="phone"
	          placeholder="Phone"
	          value={inputs.phone || ""} 
	          onChange={handleChange}
	        />
	      </label>

	      {/*<label>Date of Birth:
	        {errors.dob && <p className="form-error-text">{errors.dob}</p>}
	        <input 
	          type="date" 
	          name="dob" 
	          value={inputs.dob|| ""} 
	          onChange={handleChange}
	        />
	      </label>
	      */}

	       <div className="recaptcha-wrap" >
	        {recaptchaError && <p className="form-error-text">reCAPTCHA is required</p>}

	        <ReCAPTCHA
	          sitekey={RECAPTCHA_V2_PUBLIC}
	          onChange={handleRecaptchaChange}
	          ref={recaptchaRef} 
	        />
	      </div>

	      <input type="hidden" name="workshopID" value={workshopID} />

      	 <input type="submit" value={submitBtnText} />

      </form>

      <Modal message={modalMessage} title={modalTitle} onClose={closeModal} />

    </div>
    );

};
export default WorkshopForm;