import React, { useState } from 'react';

import WorkshopSelector from "../Components/WorkshopSelector.jsx"
//import WorkshopEditor from "../Components/WorkshopEditor.jsx"

const Admin = () => {

	
	return (
		<div>
			<h2>Admin Page</h2>


			<WorkshopSelector />


		</div>
	
	);



};

export default Admin;